.title1 {
  font-size: 53px;
  line-height: 1.16981132;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
  hyphens: auto;
}
.title1:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title2 {
  font-size: 35px;
  line-height: 1.17142857;
  color: var(--s-textcolor);
  font-family: 'Noto Serif', serif;
  position: relative;
  padding-bottom: 20px;
}
.area--one .title2:after,
.area--intro .title2:after,
.section--teaserSlider .title2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 9px;
  background-color: #F6A124;
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
.title3 {
  font-size: 26px;
  line-height: 1.19230769;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .title3 {
    font-size: 21px;
    line-height: 1.19047619;
  }
}
.lead {
  font-size: 24px;
  line-height: 1.26086957;
  color: var(--s-textcolor);
  font-weight: 300;
}
@media (max-width: 1023px) {
  .lead {
    font-size: 22px;
    line-height: 1.18181818;
  }
}
.copy {
  font-size: 21px;
  line-height: 1.19047619;
  color: var(--s-textcolor);
  font-weight: 300;
}
.smallText {
  font-size: 17px;
  line-height: 1.17647059;
}
.quote {
  font-family: 'Noto Serif', serif;
  font-size: 28px;
  line-height: 1.14285714;
  color: var(--s-textcolor);
  font-style: italic;
}
@media (max-width: 1023px) {
  .quote {
    font-size: 26px;
    line-height: 1.19230769;
  }
}
.footnote {
  font-size: 219px;
  line-height: 0.79908676;
  letter-spacing: 0.02em;
  color: var(--s-folding-number-color);
  font-family: 'Noto Serif', serif;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .footnote {
    font-size: 100px;
    line-height: 0.8;
  }
}
.moodSlogan {
  font-size: 53px;
  line-height: 1.16981132;
  color: #FFFFFF;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  text-align: right;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .moodSlogan {
    font-size: 22px;
    line-height: 1.08333333;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: var(--s-btn-bg);
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
  color: var(--s-btn-color);
  min-width: 112px;
  padding: 9px 26px 26px 9px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  transition: all 0.4s;
}
.button:before,
.button:after {
  content: '';
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 30px;
  height: 9px;
  background-color: var(--s-btn-deco-color);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
.button:hover:after,
.button:focus:after {
  background-color: var(--s-btn-deco-color-hover);
}
@media (max-width: 1023px) {
  .button {
    padding: 5px 30px 30px 12px;
    font-size: 21px;
    line-height: 1.19047619;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0 40px;
}
div.sub1 > .item {
  position: relative;
}
div.sub1 > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
  transition: all 0.4s;
  padding-bottom: 20px;
  color: #023E84;
}
div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 9px;
  background-color: #F6A124;
  transition: all 0.4s;
}
div.sub1 > .item:hover > .menu,
div.sub1 > .item:focus > .menu,
div.sub1 > .item.path > .menu {
  color: #F6A124;
}
div.sub1 > .item:hover > .menu:after,
div.sub1 > .item:focus > .menu:after,
div.sub1 > .item.path > .menu:after {
  width: 20px;
}
@media (max-width: 1400px) {
  div.sub1 {
    gap: 0 20px;
  }
}
@media (max-width: 1200px) {
  div.sub1 {
    gap: 0 10px;
  }
  div.sub1 > .item > .menu {
    font-size: 18px;
    line-height: 1.16666667;
  }
}
div.sub2 {
  background-color: #FFFFFF;
  width: 0;
  max-height: 1000px;
  position: absolute;
  left: -12px;
  top: calc(100% - 9px);
  box-sizing: border-box;
  padding: 12px 0 42px;
  transition: all 0.6s;
  overflow: hidden;
  pointer-events: none;
  z-index: 2000;
}
.sub1 .item.exit div.sub2 {
  right: 0;
  left: unset;
}
div.sub2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 20px;
  height: 9px;
  background-color: #F6A124;
  opacity: 0;
  transition: all 0.4s;
}
div.sub2 > .item {
  width: 100%;
  margin-top: 10px;
  opacity: 0;
  transition: all 0.4s;
}
div.sub2 > .item.init {
  margin-top: 0;
}
div.sub2 > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
  transition: all 0.4s;
  color: #023E84;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus {
  color: #F6A124;
}
div.sub2 > .item > .menu.path {
  color: #F6A124;
}
.navigation div.sub1 > .item:hover > div.sub2 {
  width: 210px;
  pointer-events: all;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.4s;
}
.navigation div.sub1 > .item:hover > div.sub2 > .item {
  opacity: 1;
  transition: all 0.4s 0.1s;
}
.navigation div.sub1 > .item:hover > div.sub2:after {
  opacity: 1;
  transition: all 0.4s 0.1s;
  left: 12px;
}
#home {
  height: 73px;
  margin-bottom: 20px;
}
@media (max-width: 1400px) {
  #home {
    height: 73px;
  }
}
.section--header {
  padding-top: 30px;
  padding-bottom: 0;
}
.container--navi {
  margin-right: var(--deskSpace);
}
.navigation {
  margin-top: 30px;
}
@media (max-width: 1400px) {
  .navigation {
    margin-top: 30px;
  }
}
.container--mood {
  margin-top: 35px;
  min-height: 385px;
  max-height: calc(100vh - 315px);
}
@media (min-width: 1500px) {
  .container--mood {
    width: calc(100% + ((100vw - 1440px) / 2));
    margin-left: calc(((100vw - 1440px) / 2) * -1);
  }
}
.container--mood .moodContent {
  max-width: 50%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-right: var(--spaceTotal);
}
.mood-background {
  width: 100vw;
  height: calc(100% - 170px);
}
.container--intro {
  width: calc(100% + 140px);
  margin-left: -140px;
  padding-left: 140px;
}
.designelement--triangles {
  width: 196px;
}
.container--facts {
  padding-left: 140px;
  padding-bottom: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
@media (min-width: 1440px) {
  .container--facts {
    width: calc(100% + 140px);
    margin-left: -140px;
  }
}
.container--blogContent {
  grid-template-columns: repeat(12, 1fr);
}
.footarea {
  margin-top: 30px;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 1440px) {
  .footarea {
    width: calc(100% + 280px);
    margin-left: -140px;
    padding-left: 140px;
  }
}
.footpart--one {
  grid-column: 1 / span 5;
}
@media (min-width: 1440px) {
  .footpart--one {
    margin-left: 0;
  }
}
.footpart--two {
  grid-column: 10 / span 3;
  margin-right: 0;
}
.services {
  margin-top: 50px;
}
.footlogo {
  height: 87px;
}
.area--one {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitOne--1-1 {
  grid-column: span 12;
}
.area--one .unitOne--1-2 {
  grid-column: span 6;
}
.area--one .unitOne--1-3 {
  grid-column: span 4;
}
.area--one .unitTwo {
  grid-column: span 12;
  padding-top: var(--spaceUnit);
  padding-bottom: calc((var(--spaceTotal) * 4) - var(--spaceUnit));
}
@media (min-width: 1440px) {
  .area--one .unitTwo {
    width: calc(100% + 280px);
    margin-left: -140px;
    padding-left: 140px;
    padding-right: 140px;
  }
}
.area--one .unitTwo .partWidth--1-4 {
  width: calc((100% / 4) - 30px);
}
.area--one .unitTwo .less:before {
  width: 196px;
  height: 267px;
}
.area--one .unitThree {
  grid-column: span 4;
}
.area--one .unitFour {
  grid-column: span 12;
  padding-top: 150px;
  padding-bottom: 150px;
}
@media (min-width: 1440px) {
  .area--one .unitFour {
    width: calc(100% + 280px);
    margin-left: -140px;
    padding-left: 140px;
    padding-right: 140px;
    padding-top: 0;
  }
}
.area--one .unitFour .unit__background {
  width: calc(50% + 150px);
}
@media (min-width: 1220px) {
  .area--one .unitFour .unit__background {
    margin-right: calc(((100vw - 1160px) / 2) * -1);
  }
}
@media (min-width: 1920px) {
  .area--one .unitFour .unit__background {
    margin-right: -370px;
  }
}
.area--one .unitFour .unit__content {
  width: calc(50% + 150px);
}
.area--one .unitFour:before,
.area--one .unitFour:after {
  width: 105px;
  height: 88px;
}
.area--one .unitFive {
  grid-column: span 6;
}
.area--one .unitFive .partWidth--1-2 {
  grid-column: span 9;
}
.area--one .unitFive .partWidth--1-1 {
  grid-column: span 12;
}
.area--one .unitFive .partWidth--1-2 {
  grid-column: span 9;
}
.area--one .unitFive .partWidth--1-4 {
  grid-column: span 3;
}
.area--one .unit--customerSlider {
  grid-column: span 12;
}
.area--intro .unit .unit__body {
  grid-template-columns: repeat(12, 1fr);
}
.area--intro .unit .partWidth--1-1 {
  grid-column: span 12;
}
.area--intro .unit .partWidth--1-2 {
  grid-column: span 6;
}
.unit--blogImages {
  width: calc(100% + 30px);
  grid-column: 1 / span 4;
  grid-row: 1;
}
@media (min-width: 1220px) {
  .unit--blogImages {
    width: calc(100% + (100vw - 1160px) / 2);
    margin-left: calc(((100vw - 1160px) / 2) * -1);
  }
}
@media (min-width: 1920px) {
  .unit--blogImages {
    width: calc(100% + 370px);
    margin-left: -370px;
  }
}
.unit--blogContent {
  width: 100%;
  grid-column: 4 / span 3;
  grid-row: 1;
}
@media (min-width: 1220px) {
  .unit--blogContent {
    grid-column: 5 / span 8;
  }
}
/*# sourceMappingURL=./screen-large.css.map */